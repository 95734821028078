.App {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

.header-container {
    padding: 40px 40px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.header_link {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-weight:initial;
    color: black;
    text-decoration: none;
    margin-left: 20px;
}

.logo {
    width: 250px;
    height: 140px;
}

.title {
    margin-bottom: 25px;
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-size: 60px;
    text-align: center;
}

.slate {
    width: 1000px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.slate-photo {
    display: grid;
    justify-content: end;
    margin-bottom: 40px;
}

.slate-text {
    font-family: SofiaSansCondensed-VariableFont_wght;
    text-align: center;
    font-size: 62px;
    font-weight: 500;
}

.david-text {
    font-size: large;
    margin-bottom: 10px;
}

.block-technology {
    margin: 80px 75px;
}

.title-text {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-size: 25px;
    font-weight: 500;
    padding: 20px 0;
}

.description-text {
    max-width: 900px;
    font-family: Poppins-Light;
    line-height: 1.5;
    font-size: small;
    padding-bottom: 20px;
}

.wrapper-section {
    display: flex;
    flex: 1;
    padding-top: 30px;
    padding-bottom: 30px;
}

.section {
    width: 100%;
    display: grid;
    max-width: 80%;
    justify-content: center;
    flex-direction: column;
    background-color: #e0e9f1;
    padding: 40px;
}

.hot-photo {
    width: 900px;
    margin-bottom: 20px;
}

.wrapper-section_2 {
    padding-top: 75px;
}

.section_2 {
    width: 100%;
    display: flex;
    align-items: center;
}

.content-left {
    margin-right: 20px;
}

.content-right {
    margin-left: 100px;
    margin-right: 300px;

}

.test-text {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-size: larger;
}

.wrapper-section_3 {
    display: flex;
    padding-top: 75px;
    justify-content: flex-end;
}

.section_3 {
    width: 100%;
    display: grid;
    justify-content: center;
    flex-direction: column;
    background-color: #e0e9f1;
    max-width: 80%;
    padding: 80px;
}

.battery-photo {
    width: 900px;
    height: 440px;
}

.wrapper-section_4 {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 30px;
}

.section_4 {
    max-width: 90%;
    justify-content: space-between;
    width: 100%;
    padding: 80px 0;
    display: flex;
    align-items: center;
}

.book-photo {
    width: 500px;
}

.wrapper-section_5 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.section_5 {
    width: 100%;
    align-items: center;
    display: flex;
    max-width: 80%;
    flex-direction: column;
    background-color: #f1cdc5;
    padding: 40px;
}

.title-text-footer {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-size: 90px;
    text-align: center;
}

.title-text-footer_2 {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-size: 90px;
    text-align: left;
    font-weight: bold;
}

.description-text-footer {
    font-family: SofiaSansCondensed-VariableFont_wght;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 40px;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    margin: 20px;
}

.section_get {
    display: flex;
}

.email-text {
    font-weight: bold;
    font-family:SofiaSansCondensed-VariableFont_wght;
}

.phone-text {
    font-weight: bold;
    margin-top: 10px;
    font-family:SofiaSansCondensed-VariableFont_wght;
}

.company-info-text {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-weight: bold;
    margin-top: 10px;
}

.footer_link {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-weight: initial;
    color: black;
    text-decoration: none;
}

.footer-phone {
    font-family: SofiaSansCondensed-VariableFont_wght;
    font-weight: initial;
    color: black;
    text-decoration: none;
}

@media (max-width: 1280px) {

    .content-right {
        justify-items: center;
        margin: 20px;
    }
}

@media (max-width: 1024px) {
    .header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .slate-text {
        width: 99%;
    }

    .header_link {
        margin-top: 0;
    }

    .block-technology {
        margin: 40px 75px
    }

    .content-right {
        justify-items: center;
        margin: 20px;
    }

    .wrapper-section {
        width: 100%;
        padding: 0;
    }

    .section {
        flex-direction: row;
        align-items: center;
        justify-items: center;
        max-width: 100%;
    }

    .hot-photo {
        width: 100%;
    }

    .wrapper-section_2 {
        width: 100%;
        margin: 0;
    }

    .section_2 {
        align-items: center;
        justify-items: center;
    }

    .content-left {
        margin-right: 20px;
    }

    .battery-photo {
        width: 575px
    }

    .book-photo {
        width: 400px;
    }

    .section_3 {
        max-width: 100%;
        align-items: center;
        justify-items: center;
        flex-direction: column;
    }

    .section_4 {
        padding: 0;
    }

    .title-text-footer {
        font-size: 60px;
    }

    .title-text-footer_2 {
        font-size: 60px;
    }

    .wrapper-section_5 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 768px) {

    .section {
        width: 100%;
    }

    .battery-photo {
        width: 575px
    }

    .content-right {
        justify-items: center;
        margin: 0;
    }

    .content-left {
        margin: 0;
    }
}

@media (max-width: 767px) {

    .header {
        flex-direction: column;
    }

    .slate-photo {
        justify-content: center;
    }

    .slate {
        max-width: 100%;
    }

    .title {
        font-size: 20px;
        text-align: center;
    }

    .slate-text {
        text-align: center;
        font-size: 35px;
    }

    .block-technology {
        padding: 0;
        margin: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wrapper-section {
        width: 100%;
    }

    .section {
        flex-direction: column;
        max-width: 100%;
    }

    .hot-photo {
        width: 100%;
        margin-bottom: 20px;
        aspect-ratio: 1 / 0.5;
    }

    .section_2 {
        flex-direction: column;
    }

    .content-right {
        padding: 0;
        margin: 0;
    }

    .section_3 {
        max-width: 100%;
        padding: 40px;
    }

    .content-left {
        margin-bottom: 20px;
    }

    .title-text {
        text-align: center;
    }

    .description-text {
        max-width: 360px;
        text-align: start;
    }

    .battery-photo {
        width: 100%;
        aspect-ratio: 1 / 0.5;
        height: 214px;
    }

    .section_4 {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .book-photo {
        display: none;
    }

    .section_5 {
       max-width: 100%;
        text-align: center;
        padding: 0;
    }

    .title-text-footer {
        font-size: 40px;
        padding: 40px;
    }

    .title-text-footer_2 {
        font-size: 28px;
        text-align: initial;
    }

    .description-text-footer {
        font-size: 17px;
        text-align: initial;
        margin: 0;
        padding: 10px;
    }

    .wrapper-section_5 {
        width: 100%;
    }

    .section_get {
        flex-direction: column;
        align-items: center;
        padding: 15px;
    }
}

