@font-face {
    font-family: 'MinionPro-Regular';
    src: url('./assets/fonts/MinionPro-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'SofiaSansCondensed-VariableFont_wght';
    src: url('./assets/fonts/SofiaSansCondensed-VariableFont_wght.ttf') format('truetype');
}
